import React, { useEffect, useRef, ReactNode } from "react";
import { motion, useAnimation } from "framer-motion";

interface ScrollRevealProps {
  children: ReactNode;
}

const ScrollReveal: React.FC<ScrollRevealProps> = ({ children }) => {
  const ref = useRef<HTMLDivElement>(null);
  const controls = useAnimation();

  useEffect(() => {
    const scrollHandler = () => {
      if (ref.current) {
        const top = ref.current.getBoundingClientRect().top;
        const elementHeight = ref.current.offsetHeight;

        if (
          top <= window.innerHeight &&
          top >= window.innerHeight - elementHeight / 2
        ) {
          controls.start({ opacity: 1, y: 0 });
        }
      }
    };

    if (ref.current) {
      const top = ref.current.getBoundingClientRect().top;
      if (top <= window.innerHeight) {
        controls.start({ opacity: 1, y: 0 });
      }
    }

    window.addEventListener("scroll", scrollHandler);

    return () => {
      window.removeEventListener("scroll", scrollHandler);
    };
  }, [controls]);

  return (
    <motion.div ref={ref} initial={{ opacity: 0, y: 50 }} animate={controls}>
      {children}
    </motion.div>
  );
};

export default ScrollReveal;
