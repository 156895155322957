import React from 'react';
import { Container, Col, Row } from 'react-bootstrap';
import ScrollReveal from './ScrollReveal';

const AboutPage: React.FC = () => {
    return (
        <ScrollReveal>
            <Container fluid className="p-5 section align-items-center">
                <Row className="">
                    <Col md={6} className="align-self-center text-start">
                        <h1>More About Me</h1>
                    </Col>
                    <Col md={6} className="align-self-center text-end">
                        <p>My passion for Web Development <span className="text-primary"> began at age 5. </span><br />By 8, I was creating custom designs for forums using HTML and CSS.</p>
                        <p>Now, I hold a <span className="text-primary"> Master's degree</span> in Computer Science from <span className="text-primary"> Aarhus University </span>, where I graduated with top grades.</p>
                    </Col>
                </Row>
            </Container>
        </ScrollReveal>
    );
};

export default AboutPage;
