import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import ScrollReveal from './ScrollReveal';

const ExperiencePage: React.FC = () => {
    return (
        <ScrollReveal>
            <Container fluid className="p-5 section">
                <Row>
                    <Col md={6} className="align-self-center text-start">
                        <h1>Work Experience</h1>
                    </Col>
                    <Col md={6} className="align-self-center text-end">
                        <Row className="mb-4">
                            <h5 className="text-primary">Full-Stack Developer</h5>
                            <p>Formalize (formerly Whistleblower Software)</p>
                            <p>May 2024 - Present</p>
                        </Row>
                        <Row className="mb-4">
                            <h5 className="text-primary">Junior Software Developer</h5>
                            <p>Systematic</p>
                            <p>Aug 2023 - May 2024</p>
                        </Row>
                        <Row className="mb-4">
                            <h5 className="text-primary">Intern</h5>
                            <p>Chainalysis Inc.</p>
                            <p>Sep 2022 - Aug 2023</p>
                        </Row>
                        <Row>
                            <h5 className="text-primary">Programming Teacher</h5>
                            <p>Classgap</p>
                            <p>Sep 2021 - Aug 2022</p>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </ScrollReveal>
    );
};

export default ExperiencePage;
