import React from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import HomePage from './components/HomePage';
import AboutPage from './components/AboutPage';
import ProjectsPage from './components/ProjectsPage';
import ExperiencePage from './components/ExperiencePage';
import ContactPage from './components/ContactPage';
import AppNavbar from './components/AppNavBar';

function App() {
  return (
    <div className="App">
      <AppNavbar />
      <div id="home" className="section">
        <HomePage />
      </div>
      <div id="about" className="section">
        <AboutPage />
      </div>
      <div id="projects" className="section">
        <ProjectsPage />
      </div>
      <div id="CV" className="section">
        <ExperiencePage />
      </div>
      <div id="contact" className="section">
        <ContactPage />
      </div>
    </div>
  );
}

export default App;
