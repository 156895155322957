import React from 'react';
import { Col, Row, Container, Image } from 'react-bootstrap';
import ScrollReveal from './ScrollReveal';
import { TypeAnimation } from 'react-type-animation';

const HomePage: React.FC = () => {
    return (
        <ScrollReveal>
            <Container fluid className="p-5 section">
                <Row className="align-items-center">
                    <Col md={6}>
                        <h1>Hi, I'm Mariam</h1>
                        <TypeAnimation
                            sequence={[
                                'A Full-stack Web Developer',
                            ]}
                            wrapper="span"
                            style={{ fontSize: '2em', display: 'inline-block' }}
                            className='mb-2'
                        />
                        <p>
                            <span>From </span>
                            <span role="img" aria-label="Denmark Flag">🇮🇹</span>,
                            <span> Living in </span>
                            <span role="img" aria-label="Denmark Flag">🇩🇰</span>. 
                            Passionate about consistently improving my skills and learning new technologies.
                        </p>
                    </Col>
                    <Col md={6} className="text-center">
                        <Image src="./homePage.png" alt="Mariam" className='home-img' fluid />
                    </Col>
                </Row>
                <Row className="justify-content-between mt-5">
                    <Col xs="auto" className="text-start">
                        <p>FRONTEND (ANGULAR, REACT)</p>
                    </Col>
                    <Col xs="auto" className="text-end">
                        <p>BACKEND (LARAVEL, DJANGO)</p>
                    </Col>
                </Row>
            </Container>
        </ScrollReveal>
    );
};

export default HomePage;
