import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import ScrollReveal from './ScrollReveal';

const ProjectsPage: React.FC = () => {
  return (
    <ScrollReveal>
      <Container fluid className="p-5 section">
        <Row>
          <Col md={6} className="align-self-center text-start">
            <h1>My Projects</h1>
          </Col>
          <Col md={6} className="align-self-center text-end">
            <a href="https://www.saidtappeti.com" target="_blank" rel="noreferrer">
              <img src="./work.png" alt="work" className="work-img" />
            </a>

          </Col>
        </Row>
      </Container>
    </ScrollReveal>
  );
};

export default ProjectsPage;
