import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import ScrollReveal from './ScrollReveal';

const ContactPage: React.FC = () => {
    return (
        <ScrollReveal>
            <Container className="p-5 section">
                <Row className="mb-5"><h1>Let's Work <br/> Together!</h1></Row>
                <Row className="mt-5">
                    <Col md={4}>
                        <p><span className="text-primary">LinkedIn:</span> <br/> https://www.linkedin.com/in/mariam-s-239692167/</p>
                    </Col>
                    <Col md={4}>
                        <p><span className="text-primary">GitHub:</span> <br/> https://github.com/Amaimaya</p>
                    </Col>
                    <Col md={4}>
                        <p><span className="text-primary">Email:</span> <br/> mariam.shamsali@gmail.com</p>
                    </Col>
                </Row>
            </Container>
        </ScrollReveal>
    );
};

export default ContactPage;
